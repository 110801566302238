// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import ClipboardJS from "clipboard";
import MicroModal from 'micromodal';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Click events
document.addEventListener('click', function (event) {
  // Dismiss alert boxes
  if (event.target.matches('.alert-box .dismiss')) {
    event.target.parentElement.remove();
    return event.preventDefault();
  }

  // Add fields as DIV
  if (event.target.matches('form .add_fields_div')) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp(event.target.dataset.id, 'g');
    event.target.parentElement.insertAdjacentHTML('beforebegin', event.target.dataset.fields.replace(regexp, time));
    return event.preventDefault();
  }

  // Remove json (or non-persistent) fields
  if (event.target.matches('form .remove_json_fields')) {
    event.target.closest('.fields_container').remove();
    return event.preventDefault();
  }

  // Toggle connection visibility
  if (event.target.matches('.toggleConnection')) {
    document.querySelector('#connections').classList.toggle('hide');
    return event.preventDefault();
  }
});

// Keyup events
document.addEventListener('keyup', function (event) {
  // JSONB attributes
  if (event.target.matches('form .dynamic_json_fields')) {
    var value = event.target.value.toLowerCase();
    var elements = event.target.parentElement.getElementsByTagName('input');
    for (let element of elements) {
      element.setAttribute('id', 'connection_credentials_' + value);
      element.setAttribute('name', 'connection[credentials][' + value + ']');
      element.setAttribute('placeholder', 'value for ' + value);
    }
  }
});

document.addEventListener('turbolinks:load', function() {

  new ClipboardJS('.clipboard');

  MicroModal.init();

  document.querySelectorAll('.circle').forEach((element) => {
    var color = stringToHslColor(element.dataset.name, 55 , 35);
    element.style.backgroundColor = color
    if (element.dataset.name !== undefined) {
      element.style.color = '#ffffff'
    }
  });

})


function stringToHslColor(str, s, l) {
  if (str === undefined) {
    return;
  }
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}
